import styles from "./styles.module.scss";
import { FC } from "react";
import { furniture } from "../../../../../../constants/reservation/furnitures";
import FurnitureElement from "../FurnitureElement/FurnitureElement";
import NumberSlider from "./components/number-slider/NumberSlider";
import ExtraInNumber from "./components/extra-in-number/ExtraInNumber";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { typeSelection } from "../../../../../../store/slices/rangePicker.slice";
import { sumFormatted } from "../../../../../../helpers/sumFormatted";
import { useTranslation } from "react-i18next";

const NumberReservation: FC<any> = ({ type, count, price, imgs, index, text }) => {
  const { t } = useTranslation();
  const furniture = [
    {
      title: "Wi-Fi",
    },
    {
      title: t("tv"),
    },
    {
      title: t("conditioner"),
    },
    {
      title: t("telephone"),
    },
    {
      title: t("fridge"),
    },
    {
      title: t("tea"),
    },
  ];
  const goodNumber = [null, null, t("doubleRoom"), t("threeBedRoom")];
  const numbers = useAppSelector((state) => state.rangePickerReducer.numbers);
  const dispatch = useAppDispatch();

  return (
    <div className={styles.numberReservation}>
      <div className={styles.sliderAndInfo}>
        <NumberSlider imgs={imgs} path={type.toLowerCase()} />
        <div className={styles.info}>
          <h2 className={styles.title}>{type}</h2>
          <h4 className={styles.humansCapacity}>{goodNumber[count]}</h4>
          <div className={styles.furniture}>
            {furniture.map((element) => (
              <FurnitureElement {...element} key={element.title} />
            ))}
          </div>
          <p className={styles.text}>{text}</p>
          <ExtraInNumber index={index} />
        </div>
      </div>
      <div className={styles.reservation}>
        <div className={styles.priceWrapper}>
          <h3 className={styles.priceText}>{t("priceOneDay")}</h3>
          <h2 className={styles.price}>{sumFormatted(price)} {t("sum")}</h2>
        </div>
        <div className={styles.completion}>
          <div className={styles.conditionsAndQuests}>
            <div className={styles.cancelAndBank}>
              <button className={styles.cancelBtn}>{t("cancelCon")}</button>
              <button className={styles.bankMapBtn}>
                {t("paymentCard")}
              </button>
            </div>
          </div>
          <div className={styles.chooses}>
            {numbers &&
              numbers.map((number: any) => {
                return (
                  number.type === "none" && (
                    <div className={styles.chooseWrapper}>
                      <h4 className={styles.counts}>
                        {number.adults} {t("twoOrThreeAdults")}, {number.childs} {t("children")}
                      </h4>
                      <button
                        className={styles.chooseBtn}
                        onClick={() =>
                          dispatch(
                            typeSelection({
                              id: number.index - 1,
                              type: type.toLowerCase(),
                            }),
                          )
                        }
                      >
                        {t("choose")}
                      </button>
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NumberReservation;
