import styles from "./styles.module.scss";
import {FC, useState} from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header/Header";
import { useWindowSize } from "../../hooks/windowSize";
import BurgerMenu from "../../components/BurgerMenu/BurgerMenu";

const MainLayout: FC = () => {
  const windowSize = useWindowSize();
    const [languageAcive, setLanguageActive] = useState("RU")

  return (
    <div className={styles.container}>
      {windowSize > 950 ? <Header languageActive={languageAcive}
                                  setLanguageActive={setLanguageActive} />
                        : <BurgerMenu languageActive={languageAcive}
                                      setLanguageActive={setLanguageActive} />}
      <div className={styles.outlet}>
        <Outlet />
      </div>
    </div>
  );
};
export default MainLayout;
