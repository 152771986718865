import styles from "./styles.module.scss";
import { FC, useState } from "react";
import { classes } from "../../../../constants/home/сlasses";
import HeadChapter from "../../components/HeadChapter/HeadChapter";
import classNames from "classnames";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { useWindowSize } from "../../../../hooks/windowSize";
import { useTranslation } from "react-i18next";

const Classes: FC = () => {
  const { t } = useTranslation();
  const [moreRests, setMoreRests] = useState(false);
  const [visibleRests, setVisibleRests] = useState(5);

  const windowSize = useWindowSize();

  const { ref: refClasses, inView: inViewClasses } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const onMoreRests = async () => {
    setMoreRests(true);
    setTimeout(() => {
      setVisibleRests((prevCount) => prevCount + 20);
    }, 1000);
  };

  const onFiveRests = async () => {
    // Анимируем скрытие элементов
    setVisibleRests((prevCount) => prevCount - 20);
    setTimeout(() => {
      setMoreRests(false);
    }, 1000); // Ожидаем завершения анимации перед изменением состояния
  };

  const classes = [
    {
      id: 1,
      img: "assets/home/classes/tubing.png",
      title: t("tubing"),
      y: 300,
      x: -300,
    },
    {
      id: 2,
      img: "assets/home/classes/konferenc.png",
      title: t("hall"),
      y: 300,
      x: -300,
    },
    {
      id: 3,
      img: "assets/home/classes/pool.png",
      title: t("pool"),
      y: 300,
      x: 0,
    },
    {
      id: 4,
      img: "assets/home/classes/cave.png",
      title: t("cave"),
      y: 300,
      x: 300,
    },
    {
      id: 5,
      img: "assets/home/classes/sauna.png",
      title: t("sauna"),
      y: 300,
      x: 300,
    },
    {
      id: 6,
      img: "assets/home/classes/lazer.png",
    },
    {
      id: 7,
      img: "assets/home/classes/ion.png",
    },
    {
      id: 8,
      img: "assets/home/classes/elektro.png",
    },
    {
      id: 9,
      img: "assets/home/classes/elektro3.png",
    },
    {
      id: 10,
      img: "assets/home/classes/massage2.png",
    },
    {
      id: 11,
      img: "assets/home/classes/massage.png",
    },
    {
      id: 12,
      img: "assets/home/classes/cave2.png",
    },
    {
      id: 13,
      img: "assets/home/classes/ion2.png",
    },
    {
      id: 14,
      img: "assets/home/classes/lazer2.png",
    },
    {
      id: 15,
      img: "assets/home/classes/elektro2.png",
    },
  ];

  return (
    <div className={styles.container} id="classes">
      <HeadChapter title={t("doingResort")} color={false} />
      <div className={styles.content} ref={refClasses}>
        <div className={styles.rests}>
          {classes.slice(0, visibleRests).map((rest, index) => (
            <motion.div
              key={rest.id}
              className={classNames(styles.restWrapper, {
                [styles.moreRestsActive]: moreRests || windowSize < 1370,
              })}
              animate={
                inViewClasses && visibleRests <= 5
                  ? { y: 0, x: 0, opacity: 1 }
                  : { opacity: 1, y: 0 }
              }
              initial={
                visibleRests <= 5 && windowSize > 1000
                  ? { y: rest.y, x: rest.x, opacity: 0 }
                  : { opacity: 0, y: 20 }
              }
              transition={
                visibleRests <= 5
                  ? { duration: 0.8 }
                  : { duration: 0.5, delay: index * 0.1 }
              }
            >
              <div className={styles.restBackground}></div>
              <h2 className={styles.restTitle}>{rest.title}</h2>
              <img
                src={rest.img}
                alt="rest"
                key={rest.id}
                className={classNames(styles.restImg)}
                loading="lazy"
              />
            </motion.div>
          ))}
        </div>
        {visibleRests < classes.length ? (
          <button className={styles.moreRestsBtn} onClick={() => onMoreRests()}>
            {t("more")}
            <img
              alt="arrow"
              src="assets/home/arrow.png"
              className={styles.arrow}
            />
          </button>
        ) : (
          <button className={styles.hide} onClick={() => onFiveRests()}>
            {t("hide")}
            <img
              alt="arrow"
              src="assets/home/arrow.png"
              className={styles.arrow}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default Classes;
