import styles from "./styles.module.scss";
import { FC, useEffect, useState } from "react";
import Menu from "../Menu/Menu";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface BurgerMenuProps {
    languageActive: string,
    setLanguageActive: any,
}

const BurgerMenu: FC<BurgerMenuProps> = ({languageActive, setLanguageActive}) => {
  const [menuActive, setMenuActive] = useState(false);
  const { t, i18n } = useTranslation();
  const items: any = [
    {
      to: "aboutUs",
      value: t("about"),
    },
    {
      to: "classes",
      value: t("doing"),
    },
    {
      to: "numbers",
      value: t("numbers"),
    },
    {
      to: "news",
      value: t("news"),
    },
    {
      to: "Новости",
      value: t("contacts"),
    },
  ];

  const [scrolling, setScrolling] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolling]);
  // menuActive ? styles.active : ""
  return (
    <div className={styles.mobileHeader}>
      <nav
        className={classNames({
          [styles.location]: location.pathname !== "/",
          [styles.scrolling]: scrolling,
          [styles.active]: menuActive,
        })}
      >
          {menuActive
              ?  (<div className={styles.numbers}>
                  <div className={styles.numbersWrapper}>
                      <a className={styles.number} href="tel:(+99895 212 00 20)">+99895 212 00 20</a>
                      <a className={styles.number} href="tel:(+99895 212 02 20)">+99895 212 02 20</a>
                  </div>
                  <div className={styles.numbersWrapper}>
                      <a className={styles.number} href="tel:(+99895 001 11 44)">+99895 001 11 44</a>
                      <a className={styles.number} href="tel:(+99895 001 11 88)">+99895 001 11 88</a>
                  </div>
                 </div>)
              : (<img
                  src="/assets/home/main/logo.png"
                  className={styles.logo}
                  alt="logo"
              />)
          }

        <div className={styles.btnWrapper}>
          <div
            className={styles.burgerBtn}
            onClick={() => setMenuActive(!menuActive)}
          >
            <span className={styles.span1} />
            <span className={styles.span2} />
            <span className={styles.span3} />
          </div>
        </div>
      </nav>
      <Menu
        items={items}
        menuActive={menuActive}
        setMenuActive={setMenuActive}
        languageActive={languageActive}
        setLanguageActive={setLanguageActive}
      />
    </div>
  );
};
export default BurgerMenu;
