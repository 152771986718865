import styles from "./styles.module.scss";
import { FC, useState } from "react";
import NumberSlider from "../NumberReservation/components/number-slider/NumberSlider";
import { furniture } from "../../../../../../constants/reservation/furnitures";
import FurnitureElement from "../FurnitureElement/FurnitureElement";
import classNames from "classnames";
import ExtraInNumber from "../NumberReservation/components/extra-in-number/ExtraInNumber";
import { typeSelection } from "../../../../../../store/slices/rangePicker.slice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { sumFormatted } from "../../../../../../helpers/sumFormatted";
import { useTranslation } from "react-i18next";

const MobileNumberReservation: FC<any> = ({
  type,
  count,
  price,
  imgs,
  index,
}) => {
  const { t } = useTranslation();
  const furniture = [
    {
      title: "Wi-Fi",
    },
    {
      title: t("tv"),
    },
    {
      title: t("conditioner"),
    },
    {
      title: t("telephone"),
    },
    {
      title: t("fridge"),
    },
    {
      title: t("tea"),
    },
  ];
  const goodNumber = [null, null, t("doubleRoom"), t("threeBedRoom")];
  const [activeFurniture, setActiveFurniture] = useState(false);
  const numbers = useAppSelector((state) => state.rangePickerReducer.numbers);
  const dispatch = useAppDispatch();

  return (
    <div className={styles.container}>
      <NumberSlider imgs={imgs} path={type.toLowerCase()} />
      <div className={styles.info}>
        <div className={styles.titleAndFurniturs}>
          <h2 className={styles.title}>{type}</h2>
          <button
            className={classNames({
              [styles.furnitureUnactiveBtn]: !activeFurniture,
              [styles.furnitureActiveBtn]: activeFurniture,
            })}
            onClick={() => setActiveFurniture(!activeFurniture)}
          >
            <img
              src="/assets/reservation/activeFurniture.png"
              alt="arrow"
              className={styles.activeFurniture}
            />
          </button>
        </div>
        {activeFurniture && (
          <div className={styles.furniture}>
            {furniture.map((element) => (
              <FurnitureElement {...element} key={element.title} />
            ))}
          </div>
        )}
        <h4 className={styles.humansCapacity}>{goodNumber[count]}</h4>
        <ExtraInNumber index={index} />
        <h3 className={styles.priceText}>{t("priceOneDay")}</h3>
        <div className={styles.priceAndTwoButtons}>
          <div className={styles.cancelAndBank}>
            <h4 className={styles.cancel}>{t("cancelCon")}</h4>
            <h4 className={styles.bank}>{t("paymentCard")}</h4>
          </div>
          <h3 className={styles.price}>{sumFormatted(price)} {t("sum")}</h3>
        </div>
        <div className={styles.chooses}>
          {numbers.map((number: any) => {
            return (
              number.type === "none" && (
                <div className={styles.chooseWrapper}>
                  <h4 className={styles.counts}>
                    {number.adults} {t("twoOrThreeAdults")}, {number.childs} {t("children")}
                  </h4>
                  <button
                    className={styles.chooseBtn}
                    onClick={() =>
                      dispatch(
                        typeSelection({
                          id: number.index - 1,
                          type: type.toLowerCase(),
                        }),
                      )
                    }
                  >
                    {t("choose")}
                  </button>
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default MobileNumberReservation;
