import styles from "./styles.module.scss";
import { FC } from "react";
import Logo from "../../../../components/Header/components/Logo/Logo.tsx";
import { Link } from "react-scroll";
import classNames from "classnames";
import { useWindowSize } from "../../../../hooks/windowSize";
import { useTranslation } from "react-i18next";

interface FooterTypeProps {
  marginTop?: boolean;
}

const Footer: FC<FooterTypeProps> = ({ marginTop }) => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  return (
    <div
      className={classNames(styles.container, {
        [styles.marginTop]: marginTop,
      })}
      id="contacts"
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.connection}>
            <div className={styles.logo}>
              <Logo color="white" width={215} />
            </div>
            <div className={styles.socialMedias} style={{ marginTop: "-40px" }}>
              <img
                alt="sm"
                src="/assets/home/footer/telegram.png"
                className={styles.socialMedia}
              />
              <img
                alt="sm"
                src="/assets/home/footer/instagram.png"
                className={styles.socialMedia}
              />
              <img
                alt="sm"
                src="/assets/home/footer/facebook.png"
                className={styles.socialMedia}
              />
            </div>
            <div className={styles.numbers} style={{ marginTop: "-2px" }}>
              <a className={styles.number} href="mailto:sevarsoy@mail.ru">sevarsoy@mail.ru</a>
            </div>
            <div className={styles.adress}>
              {t("realAddres")}
            </div>
          </div>
          {windowSize > 870 && (
            <div className={styles.navigation}>
              <h3 className={styles.head}>{t("whatToDo")}</h3>
              <ul className={styles.list}>
                <li className={styles.listElement}>{t("hydro")}</li>
                <li className={styles.listElement}>{t("cave")}</li>
                <li className={styles.listElement}>{t("massage")}</li>
                <li className={styles.listElement}>{t("pool")}</li>
              </ul>
            </div>
          )}
          <div className={styles.navigation}>
            <h3 className={styles.head}>{t("aboutResort")}</h3>
            <ul className={styles.list}>
              <Link to="aboutUs" smooth offset={-100} duration={500}>
                <li className={styles.listElement}>{t("about")}</li>
              </Link>
              <Link to="numbers" smooth offset={-100} duration={500}>
                <li className={styles.listElement}>{t("rooms")}</li>
              </Link>
              <Link to="news" smooth offset={-100} duration={500}>
                <li className={styles.listElement}>{t("news")}</li>
              </Link>
            </ul>
          </div>
          {windowSize > 1200 && (<div className={styles.numbers}>
            <p className={styles.number}>{t("contact")}</p>
            <a className={styles.number} href="tel:(+99895 212 00 20)">+99895 212 00 20</a>
            <a className={styles.number} href="tel:(+99895 212 02 20)">+99895 212 02 20</a>
            <a className={styles.number} href="tel:(+99895 001 11 44)">+99895 001 11 44</a>
            <a className={styles.number} href="tel:(+99895 001 11 88)">+99895 001 11 88</a>
          </div>)}
        </div>
      </div>
    </div>
  );
};

export default Footer;
