import styles from "./styles.module.scss";
import { FC, useEffect, useState } from "react";
import { headerList } from "../../constants/home/headerList";
import classNames from "classnames";
import Logo from "./components/Logo/Logo.tsx";
import { Link } from "react-scroll";
import { Link as NavLink } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import {useTranslation} from "react-i18next";

interface HeaderProps {
    languageActive: string,
    setLanguageActive: any,
}

const Header: FC<HeaderProps> = ({languageActive, setLanguageActive}) => {
  const [scrolling, setScrolling] = useState(false);
  const location = useLocation();

    const { t, i18n } = useTranslation();

    const headerList = [
        {
            title: t("about"),
            to: "aboutUs",
        },
        {
            title: t("doing"),
            to: "classes",
        },
        {
            title: t("numbers"),
            to: "numbers",
        },
        {
            title: t("news"),
            to: "news",
        },
        {
            title: t("contacts"),
            to: "contacts",
        },
    ];

    const changeLanguage = (language: any, l: any) => {
        i18n.changeLanguage(language);
        setLanguageActive(l);
    };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolling]);
  return (
    <header
      className={classNames(styles.header, {
        [styles.scrolling]: scrolling,
        [styles.secondHeader]: location.pathname !== "/",
      })}
    >
      <Logo
        color={location.pathname !== "/" && !scrolling ? "#032E5C" : "white"}
        width={135}
      />
      <ul className={styles.list}>
        {headerList.map((element) => (
          <Link
            to={element.to}
            smooth
            offset={-100}
            duration={500}
            key={element.to}
          >
           <NavLink to='/'>
            <li className={styles.listElement}>{element.title}</li>
           </NavLink>
          </Link>
        ))}
      </ul>
      <div className={styles.languages}>
        <button className={classNames(styles.language, {[styles.active]: languageActive === "RU"})}
                onClick={() => changeLanguage("RU", "RU")}>
          RU
        </button>
        <button className={classNames(styles.language, {[styles.active]: languageActive === "UZ"})}
                onClick={() => changeLanguage("UZ", "UZ")}>
          UZ
        </button>
          <button className={classNames(styles.language, {[styles.active]: languageActive === "EN"})}
                  onClick={() => changeLanguage("EN", "EN")}>
              EN
          </button>
      </div>
    </header>
  );
};
export default Header;
