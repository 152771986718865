import styles from "./styles.module.scss";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PaymentBlock from "./chapters/PaymentBlock/PaymentBlock";
import { useTranslation } from "react-i18next";

const Payment: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      const confirmationMessage = 'Если вы обновите страницу, все данные будут потеряны. Вы уверены, что хотите продолжить?';
      event.preventDefault();
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    const handleUnload = () => {
      // Устанавливаем флаг в локальное хранилище
      localStorage.setItem('redirectAfterReload', 'true');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  useEffect(() => {
    // Проверяем флаг после загрузки страницы
    const redirectAfterReload = localStorage.getItem('redirectAfterReload');
    if (redirectAfterReload) {
      // Удаляем флаг и выполняем редирект
      localStorage.removeItem('redirectAfterReload');
      navigate('/');
    }
  }, [navigate]);

  return (
    <div className={styles.container}>
      <h1 className={styles.head}>{t("enter")}</h1>
      <button className={styles.backToNumbers} onClick={() => navigate(-1)}>
        {t("toRooms")}
      </button>
      <PaymentBlock />
    </div>
  );
};
export default Payment;
