import styles from "./styles.module.scss";
import { FC } from "react";
import { news } from "../../../../constants/home/news";
import OneNew from "../../../components/OneNew/OneNew";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import HeadChapter from "../../components/HeadChapter/HeadChapter";
import { useNewsDataByQuery } from "../../../../api/queries/news/news.get";
import { useWindowSize } from "../../../../hooks/windowSize";
import { useTranslation } from "react-i18next";

const News: FC = () => {
  const { t } = useTranslation();
  const { data: newsData } = useNewsDataByQuery();
  const { ref: refNews, inView: inViewNews } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const windowSize = useWindowSize();

  console.log(newsData);

  return (
    <>
      {newsData && (
        <div className={styles.container} id="news" ref={refNews}>
          <HeadChapter title={t("news")} color={false} />
          <div className={styles.news}>
            <motion.div
              className={styles.bigNew}
              animate={windowSize > 1000 && inViewNews && { y: 0, x: 0, opacity: 1 }}
              initial={windowSize > 1000 && { y: 300, x: -300, opacity: 0 }}
              transition={{ duration: 0.8 }}
            >
              {newsData && <OneNew {...newsData[0]} />}
            </motion.div>
            <motion.div
              className={styles.smallNews}
              animate={windowSize > 1000 && inViewNews && { y: 0, x: 0, opacity: 1 }}
              initial={windowSize > 1000 && { y: 300, x: 300, opacity: 0 }}
              transition={{ duration: 0.8 }}
            >
              {newsData && <OneNew {...newsData[1]} small />}
              {newsData && <OneNew {...newsData[2]} small />}
            </motion.div>
          </div>
          {/*<button className={styles.allNewsBtn}>Все новости</button>*/}
        </div>
      )}
    </>
  );
};
export default News;
