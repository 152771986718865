import styles from "./styles.module.scss";
import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-scroll";
import { useWindowSize } from "../../hooks/windowSize";
import { Link as NavLink } from "react-router-dom";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

interface MenuProps {
  menuActive: boolean;
  items: any;
  setMenuActive: any;
  languageActive: string,
  setLanguageActive: any,
}

const Menu: FC<MenuProps> = ({ items, menuActive, setMenuActive, languageActive, setLanguageActive }) => {
  const windowSize = useWindowSize();

  const { t, i18n } = useTranslation();

    const changeLanguage = (language: any, l: any) => {
        i18n.changeLanguage(language);
        setLanguageActive(l);
    };

  return (
    <div
      className={`${styles.menu} ${menuActive && styles.active}`}
      onClick={() => windowSize > 750 && setMenuActive(false)}
    >
      <div className={styles.blur} />
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        <div className={styles.listAndLanguages}>
          <ul className={styles.routerList}>
            {items.map((item: any) => (
              <Link
                to={item.to}
                smooth={true}
                offset={-100}
                duration={500}
                key={item.value}
              >
                <NavLink to="/">
                  <li onClick={() => setMenuActive(false)}>{item.value}</li>
                </NavLink>
              </Link>
            ))}
          </ul>
            <div className={styles.languages}>
                <button className={classNames(styles.language, {[styles.active]: languageActive === "RU"})}
                        onClick={() => changeLanguage("RU", "RU")}>
                    RU
                </button>
                <button className={classNames(styles.language, {[styles.active]: languageActive === "UZ"})}
                        onClick={() => changeLanguage("UZ", "UZ")}>
                    UZ
                </button>
                <button className={classNames(styles.language, {[styles.active]: languageActive === "EN"})}
                        onClick={() => changeLanguage("EN", "EN")}>
                    EN
                </button>
            </div>
        </div>
      </div>
    </div>
  );
};
export default Menu;
