import styles from "./styles.module.scss";
import { FC } from "react";
import { quantities } from "../../../../constants/reservation/quantities";
import Quantity from "./components/Quantity/Quantity";
import { useInView } from "react-intersection-observer/index";
import { useTranslation } from "react-i18next";

const Quantities: FC = () => {
  const { t } = useTranslation();
  const quantities = [
    {
      img: "/assets/reservation/quantities/arms.png",
      title: t("bookDir"),
      widthImg: 43.7,
      widthTitle: 98,
    },
    {
      img: "/assets/reservation/quantities/guard.png",
      title: t("protecting"),
      widthImg: 30,
      widthTitle: 98,
    },
    {
      img: "/assets/reservation/quantities/like.png",
      title: t("convenient"),
      widthImg: 29,
      widthTitle: 98,
    },
    {
      img: "/assets/reservation/quantities/price.png",
      title: t("bestPrice"),
      widthImg: 31,
      widthTitle: 51,
    },
  ];
  // const { ref: refQuantityLeft, inView: inViewQuantityLeft } = useInView({
  //   triggerOnce: true,
  //   threshold: 0.3,
  // });

  return (
    <div className={styles.container}>
      <h1 className={styles.head}>{t("roomRes")}</h1>
      <div className={styles.quantities}>
        {quantities.map((quantity: any) => (
          <Quantity {...quantity} key={quantity.title} />
        ))}
      </div>
    </div>
  );
};
export default Quantities;
